/* eslint-disable */

import { mockUserData } from '../_mock';

import axios from 'axios';

export const setUserDetails = (setAccount) => {
  const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : mockUserData;
  setAccount({
    displayName: userDetails?.fname + ' ' + userDetails?.lname,
    email: userDetails.email,
    photoURL: userDetails?.image,
  });
};

export const getUserDetails = () => {
  return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : mockUserData;
};

export const logOutUser = (navigate) => {
  localStorage.removeItem('user');
  navigate('/login');
};

export const generateToastError = (toast, error) => {
  console.log('Eoor', error);
  if (error.response.data.message === 'Request Already sent to Admin.') {
    toast.success('Request Already sent to Admin ',3000);
  } else {
    toast.error(
      error?.response
        ? error?.response?.data?.data?.length && error?.response?.data?.data?.length > 0
          ? error?.response?.data?.data[0]?.message
          : error?.response?.data?.message
        : error.message,
      {
        duration: 3000,
      }
    );
  }
};

export const formatDateTimeSlots = (dateObjects) => {
  const result = [];

  // Map days to their corresponding index in the week
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayIndex = (date) => new Date(date).getDay();

  // Iterate over date objects and format them
  dateObjects.forEach((dateObject) => {
    const day = days[dayIndex(dateObject.start)];
    const formattedDate = {
      day,
      daySlots: [
        {
          start: new Date(dateObject.start).toISOString(),
          end: new Date(dateObject.end).toISOString(),
          // "_id": "some_generated_id" // Id is not needed
        },
      ],
      // "_id": "some_generated_id" // Id is not needed
    };

    // Check if the day is already present in the result array
    const existingDay = result.find((item) => item.day === day);

    // If the day is already present, add the slot to the existing day
    if (existingDay) {
      existingDay.daySlots.push(formattedDate.daySlots[0]);
    } else {
      result.push(formattedDate);
    }
  });

  return result;
};

function getDateForDayIndex(dayIndex) {
  // Get the current date
  const today = new Date();

  // Calculate the difference between the current day and the target day
  const dayDiff = dayIndex - today.getDay();

  // Calculate the target date by adding the difference to the current date
  const targetDate = new Date(today.setDate(today.getDate() + dayDiff));

  // Return the formatted date
  return targetDate.toDateString();
}

export const checkPastTimeSlots = (timeslots) => {
  const refactoredTimeSlotsDay = [];

  for (let t = 0; t < timeslots.length; t++) {
    const currentDayThisWeek = getDateForDayIndex(new Date(timeslots[t].start).getDay());
    const timeSlotsDay = new Date(timeslots[t].start);

    const WeekDiff = Math.round((new Date(currentDayThisWeek) - timeSlotsDay) / (7 * 24 * 60 * 60 * 1000)) * 7;

    var startDate = timeSlotsDay;
    startDate.setDate(startDate.getDate() + WeekDiff);

    var endDate = new Date(timeslots[t].end);
    endDate.setDate(endDate.getDate() + WeekDiff);

    refactoredTimeSlotsDay.push({ start: startDate, end: endDate });

    // Check if the current timeslot is in the current week
  }

  return refactoredTimeSlotsDay;
};

export const formattedSlotsForCalendar = (timeSlotsData) => {
  let allTimeSlots = [];

  timeSlotsData.forEach((element) => {
    for (let i = 0; i < element?.daySlots.length; i += 1) {
      allTimeSlots = [
        ...allTimeSlots,
        { start: new Date(element?.daySlots[i].start), end: new Date(element?.daySlots[i].end), title: 'Available' },
      ];
    }
  });
  return checkPastTimeSlots(allTimeSlots);
};

export function getCurrentWeek() {
  const today = new Date();
  const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  const diff = today.getDate() - currentDay;

  const startOfWeek = new Date(today);
  startOfWeek.setDate(diff);

  const endOfWeek = new Date(today);
  endOfWeek.setDate(diff + 6);

  // Format the dates in the required format (YYYY-MM-DDTHH:mm:ssZ)
  const format = (date) => date.toISOString().split('.')[0] + 'Z';

  return {
    start: format(startOfWeek),
    end: format(endOfWeek),
  };
}

export const accessUserCalendar = async (accessToken, setCurrentUserGoogleEvents) => {
  const { start, end } = getCurrentWeek();

  try {
    const response = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/primary/events`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        timeMin: start,
        timeMax: end,
        singleEvents: true,
        orderBy: 'startTime',
      },
    });

    if (response.status === 200) {
      const data = response.data;

      const refactoredGoogleEventsTimeSlots = [];
      data.items.forEach((item) => {
        refactoredGoogleEventsTimeSlots.push({
          start: new Date(item?.start?.dateTime),
          end: new Date(item?.end?.dateTime),
          title: item?.summary,
        });
      });

      setCurrentUserGoogleEvents(refactoredGoogleEventsTimeSlots);

      return { eventsFound: true };
    }
  } catch (err) {
    if (err.response.status === 401) {
      return { eventsFound: false };
    }
  }
};

export function isDateInCurrentWeek(dateString) {
  // Parse the input date string
  const inputDate = new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the start date of the current week (Sunday)
  const currentWeekStartDate = new Date(currentDate);
  currentWeekStartDate.setDate(currentDate.getDate() - currentDate.getDay());

  // Calculate the end date of the current week (Saturday)
  const currentWeekEndDate = new Date(currentWeekStartDate);
  currentWeekEndDate.setDate(currentWeekStartDate.getDate() + 6);

  // Check if the input date is within the current week

  return inputDate >= currentWeekStartDate && inputDate <= currentWeekEndDate;
}

export function getHourlyFormat(date) {
  // Get hours and minutes from the date

  const formattedDate = new Date(date);
  const hours = formattedDate.getHours();
  const minutes = formattedDate.getMinutes();

  // Format hours and minutes as HH:mm
  const formattedHours = (hours < 10 ? '0' : '') + hours;
  const formattedMinutes = (minutes < 10 ? '0' : '') + minutes;

  // Return the formatted time
  return `${formattedHours}:${formattedMinutes}:00`;
}

export const formatDateTimeInDaySlots = (timeslots) => {
  function isoToWeekday(isoWeekday) {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return weekdays[isoWeekday % 7];
  }

  // Group timeslots by day of the week
  const groupedTimeslots = {};
  timeslots.forEach((slot) => {
    const start = new Date(slot.start);
    const day = isoToWeekday(start.getDay());
    if (!groupedTimeslots[day]) {
      groupedTimeslots[day] = [];
    }
    groupedTimeslots[day].push(slot);
  });

  // Construct output format
  const output = Object.keys(groupedTimeslots).map((day) => ({
    day,
    daySlots: groupedTimeslots[day],
  }));

  return output;
};

export const meetingIsNotInPast = (item, currentTime) => {
  return (
    (currentTime.getFullYear() === new Date(item?.scheduleTime).getFullYear() ||
      currentTime.getFullYear() < new Date(item?.scheduleTime).getFullYear()) &&
    (currentTime.getMonth() === new Date(item?.scheduleTime).getMonth() ||
      currentTime.getMonth() < new Date(item?.scheduleTime).getMonth()) &&
    (currentTime.getDate() === new Date(item?.scheduleTime).getDate() ||
      currentTime.getDate() < new Date(item?.scheduleTime).getDate())
  );
};

export const validatePassword = (value) => {
  if (!value) {
    return 'Password is required';
  }
  if (value.length < 8 || value.length > 30) {
    return 'Password should be between 8 and 30 characters';
  }
  if (!/(?=.*[A-Z])/.test(value)) {
    return 'Password should contain at least 1 capital letter';
  }
  if (!/(?=.*[a-z])/.test(value)) {
    return 'Password should contain at least 1 lowercase letter';
  }
  if (!/(?=.*\d)/.test(value)) {
    return 'Password should contain at least 1 numeric digit';
  }
  if (!/(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?\/\\|\-=])/.test(value)) {
    return 'Password should contain at least 1 special character';
  }

  return true;
};

export const isTenMinutesRemainingOrTimePassed = (scheduleTime, cancelTime) => {
  // Get the current time
  var currentTime = new Date();

  // Calculate the time difference in milliseconds
  var timeDifference = currentTime - new Date(scheduleTime);

  // Convert milliseconds to minutes
  var timeDifferenceInMinutes = timeDifference / 60000;

  if (cancelTime) {
    if (timeDifferenceInMinutes >= 60) {
      return true;
    } else {
      return false;
    }
  }

  // Check if the time difference is less than 10 minutes or if the current time is greater than the schedule time
  if (timeDifferenceInMinutes >= -10 && timeDifferenceInMinutes <= 60) {
    return true;
  } else {
    return false;
  }
};

export function calculateCounts(data) {
  const monthlyCounts = {};
  const annualCounts = { M: {}, C: {} };

  data &&
    data?.forEach((entry) => {
      const timestamp = new Date(entry.timestamp);
      const month = timestamp.toISOString().slice(0, 7);
      const year = timestamp.getFullYear();
      const msCount = entry.surveyData.Ms.length;
      const csCount = entry.surveyData.Cs.length;

      // Update monthly counts
      if (!monthlyCounts[month]) {
        monthlyCounts[month] = { M: 0, C: 0 };
      }
      monthlyCounts[month].M += msCount;
      monthlyCounts[month].C += csCount;

      // Update annual counts
      if (!annualCounts.M[year]) {
        annualCounts.M[year] = 0;
      }
      if (!annualCounts.C[year]) {
        annualCounts.C[year] = 0;
      }
      annualCounts.M[year] += msCount;
      annualCounts.C[year] += csCount;
    });

  return { monthlyCounts, annualCounts };
}

export function calculateAverageMonthlyCounts(monthlyCounts, dataLength) {
  const averageMonthlyCounts = {};

  Object.entries(monthlyCounts)?.forEach(([month, counts]) => {
    const totalMs = counts.M;
    const totalCs = counts.C;
    averageMonthlyCounts[month] = { M: totalMs / dataLength, C: totalCs / dataLength };
  });

  return averageMonthlyCounts;
}

export const mergeTheGoals = (mainObj, mergedObjects, mergedObjectsIncompleteGoals, obj, userDetails) => {
  const edifierId = userDetails?.role === 'TEACHER' ? mainObj?.goalsFrom?._id : mainObj?.goalsFor?._id;

  if (
    (obj?.isCompleted && !mergedObjects[edifierId]) ||
    (obj?.isCompleted === false && !mergedObjectsIncompleteGoals[edifierId])
  ) {
    if (obj?.isCompleted) {
      mergedObjects[edifierId] = {
        goals: [{ _id: mainObj?._id, ...obj }],
        name:
          userDetails?.role === 'TEACHER'
            ? `${mainObj?.goalsFrom?.fname} ${mainObj?.goalsFrom?.lname}`
            : `${mainObj?.goalsFor?.fname} ${mainObj?.goalsFor?.lname}`,
      };
    } else {
      mergedObjectsIncompleteGoals[edifierId] = {
        goals: [{ _id: mainObj?._id, ...obj }],
        name:
          userDetails?.role === 'TEACHER'
            ? `${mainObj?.goalsFrom?.fname} ${mainObj?.goalsFrom?.lname}`
            : `${mainObj?.goalsFor?.fname} ${mainObj?.goalsFor?.lname}`,
      };
    }

    // delete mergedObjects[edifierId].edifier;
  } else {
    try {
      if (obj?.isCompleted) {
        if (mergedObjects[edifierId]?.goals) {
          mergedObjects[edifierId].goals = [...mergedObjects[edifierId]?.goals, { _id: mainObj?._id, ...obj }];
        } else {
          mergedObjects[edifierId].goals = [{ _id: mainObj?._id, obj }];
        }
      } else {
        if (mergedObjectsIncompleteGoals[edifierId]?.goals) {
          mergedObjectsIncompleteGoals[edifierId].goals = [
            ...mergedObjectsIncompleteGoals[edifierId]?.goals,
            { _id: mainObj?._id, ...obj },
          ];
        } else {
          mergedObjectsIncompleteGoals[edifierId].goals = [{ _id: mainObj?._id, ...obj }];
        }
      }
    } catch (e) {}
  }
};

export const downloadExcelReport = (
  toast,
  setIsReportLoading,
  token,
  setOpenDialogReportDialog,
  reportData,
  setReportData
) => {
  fetch(
    `${process.env.REACT_APP_SERVER_URL}/api/admin/report?start=${reportData.startDate}&end=${reportData.endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      console.log('DOWNLOAD FILE RESPONSE', response);
      if (!response.ok) {
        toast.error('Failed to download Excel');
        setIsReportLoading(false);
        setOpenDialogReportDialog(false);
        return Promise.reject('Failed to download Excel');
      }
      return response.blob(); // Convert the response to a blob
    })
    .then((blob) => {
      console.log('DOWNLOAD BLOB', blob);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'exported_data.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setReportData({});
      setIsReportLoading(false);
      setOpenDialogReportDialog(false);
    })
    .catch((error) => {
      console.error(error);
      toast.error('An error occurred while downloading the report.');
      setIsReportLoading(false);
      setOpenDialogReportDialog(false);
    });
};

export function sortByLname(coachesRecord) {
  return coachesRecord.sort((a, b) => {
    const lnameA = a.lname.toUpperCase();
    const lnameB = b.lname.toUpperCase();

    if (lnameA < lnameB) {
      return -1;
    }
    if (lnameA > lnameB) {
      return 1;
    }
    return 0;
  });
}
