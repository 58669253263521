// @mui
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useQueryClient } from 'react-query';

// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
//
import AccountPopover from './AccountPopover';

import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = '100%';

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 69.507;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  background: '#FFF',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Header({ onOpenNav, showNav, data }) {
  const queryClient = useQueryClient();

  //  const {data}= useQueryGetNotifications(toast,userDetails?.token,navigate,queryClient)


  return (
    <StyledRoot>
      <StyledToolbar sx={{ opacity: showNav && 0.7, pointerEvents: showNav && 'none' }}>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <Searchbar /> */}
          {/* <LanguagePopover /> */}
          <NotificationsPopover data={data} />
          {localStorage.getItem('user') && <AccountPopover queryClient={queryClient} />}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
