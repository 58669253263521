/* eslint-disable */

import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { setUserDetails } from '../../../utils';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    path: '/dashboard/app',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    path: '/dashboard/my-profile',
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ queryClient }) {
  const [open, setOpen] = useState(null);
  const [account, setAccount] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    setUserDetails(setAccount);
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    const splittedName=name?.split(' ')

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children:splittedName[1][0]? `${splittedName[0][0]}${splittedName[1][0]}`:`${splittedName[0][0]}`,
    };
  }

  return (
    <>
      <Box sx={{ display: 'flex', cursor: 'pointer' }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{ mr: '16.24px', display: 'grid', alignItems: 'center', textTransform: 'capitalize' }}
        >
          {account?.displayName?.toLowerCase()}
        </Typography>

        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          {account.photoURL ? (
            <Avatar src={`${account.photoURL}?${Date.now()}`} alt="photoURL" />
          ) : (
            account?.displayName && <Avatar {...stringAvatar(account?.displayName?.toLowerCase())} />
          )}
        </IconButton>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" sx={{ color: 'text.titles' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={(e) => {
                handleClose(e);
                option.path && navigate(option.path);
              }}
              sx={{ pointerEvents: !option.path && 'none', opacity: !option.path && 0.3 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          sx={{ m: 1 }}
          onClick={(e) => {
            localStorage.removeItem('user');
            queryClient.removeQueries();

            navigate('/login');

            handleClose(e);
          }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
