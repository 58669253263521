import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/logo';

const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  marginTop: '0',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  paddingTop: '20px',
}));
const VerifyDocuSign = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Box sx={{ minWidth: '100vh' }}>
        <Logo
          sx={{
            position: 'fixed',
            width: '100%',
            justifyContent: 'center',
            background: 'white',
            p: '18px 0 12px 0',
          }}
        />

        {/* <Helmet>
        <title> Verify | Edifying Teachers </title>
      </Helmet> */}

        {/* <Toaster position="top-right" reverseOrder={false} /> */}
        <StyledContent sx={{ background: 'rgba(0, 0, 0, 0.06)' }}>
          <Box
            sx={{
              height: '489px',
              width: '601px',
              boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
              borderRadius: '20px',
              mx: 'auto',
              background: 'white',
            }}
          >
            <Box component="img" src="/assets/emailVerifiedCheck.svg" sx={{ mx: 'auto' }} />

            <Typography variant="mainTitles" sx={{ textAlign: 'center', color: 'text.titles', mt: '12px' }}>
              Congratulations
            </Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: '700', lineHeight: '28px' }}>
              You just need 2 steps to complete your onboarding
            </Typography>
          <div >
          <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '28px',paddingX:"",marginTop:"1rem",marginRight:"1.8rem" }}>
              1. Verify your account through your provided email
            </Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '28px',paddingX:"66px" }}>
              2. Sign an agreement document to register succesfully
            </Typography>
          </div>
            <Box sx={{ textAlign: 'center' }}>
              <Button sx={{ mt: '16px' }} variant="contained" color="main" onClick={() => navigate('/login')}>
                Login to Your Account
              </Button>

              <Typography
                variant="specs"
                sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '28px', mt: '20px' }}
              >
                Thank you for choosing Edifying Teachers!
              </Typography>
            </Box>
          </Box>

          {/* {isLoading && (
          <Typography variant="h3" sx={{ mx: 'auto', textAlign: 'center' }}>
            Verifying User ... <CircularProgress />
          </Typography>
        )}
        {!isLoading && apiResponse && apiResponse !== 'success' && (
          <Typography variant="error" sx={{ textAlign: 'center' }}>
            {apiResponse}
          </Typography>
        )} */}
        </StyledContent>
      </Box>
    </div>
  );
};

export default VerifyDocuSign;
