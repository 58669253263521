/* eslint-disable */

import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { logOutUser, generateToastError } from '../../utils';
import { queryKeys } from './queryKeys';

// all the react query requests will start from here

const httpStatuses = { unauthorized: 401, error: 500 };

export const useMutationOnboardRole = (payload, toast, handleClose, userRole, queryClient, navigate) => {
  const queryKey = [queryKeys.onboard, payload];
  const queryFn = () =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/register`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      console.log('queryFn', success);
      if (userRole && userRole === 'admin') {
        toast.success(
          `${success.data.message}, This account will be activated once user verify their identity using their email`,
          {
            duration: 5000,
          }
        );
        handleClose();
        queryClient.invalidateQueries({ queryKey: [queryKeys.getCoachesList] });

        return;
      }
      if (success.data.data.signingUrl) {
        console.log('REDIRECTING');
        // Open the signing URL in a new tab
        toast.success(`${success.data.message}, For activating your account please check your email`, {
          duration: 5000,
        });
        navigate('/verify-docusign');
        setTimeout(() => {
          window.open(success.data.data.signingUrl, '_blank');
        }, 5000);
      } else {
        toast.success(`${success.data.message}, For activating your account please check your email`, {
          duration: 5000,
        });

        // Redirect to login
        navigate('/login');
      }
    },
    onError: (error) => {
      try {
        generateToastError(toast, error);

        // if (userRole && userRole === 'admin') {
        //   handleClose();
        // }
      } catch (e) {
        toast.error('The errror ', e);
      }
    },
  });
};

export const useQueryVerifyUserEmail = (payload, onSuccess, onError) => {
  const queryKey = [queryKeys.verifyUser, payload];
  const queryFn = ({ queryKey }) =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/verify-email`, queryKey[1]);

  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess,
    onError,
  });
};

export const useMutationChangeUserInfo = (
  payload,
  toast,
  userToken,
  navigate,
  setEditData,
  setOpen,
  setPreviewVideo,
  setSelectedVideo,
  setOpenUploadVideo
) => {
  const queryKey = [queryKeys.changeTempPassword, payload];
  const queryFn = () =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/update-profile`, payload?.formData, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      console.log("UPDATING IMAGE",success?.data?.data?.image)
      try {
        if (success?.data?.data?.video) {
          const localStorageData = JSON.parse(localStorage.getItem('user'));

          localStorageData['video'] = success?.data?.data?.video;
          localStorage.setItem('user', JSON.stringify(localStorageData));
          setSelectedVideo(success?.data?.data?.video);
        }

        if (success?.data?.data && success?.data?.data?.image?.includes('edifying-teachers.s3')) {
          const localStorageData = JSON.parse(localStorage.getItem('user'));

          localStorageData['image'] = success?.data?.data?.image;
          localStorage.setItem('user', JSON.stringify(localStorageData));

          window.addEventListener('storage', handleStorageChange);
          // setOpen(false);
          window.location.reload();

          // console.log('The localStorage Changed data',localStorageData)
        }
      } catch (e) {}
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });
      setOpen(false);
      setEditData({});
      setPreviewVideo('');
      setOpenUploadVideo(false);
    },
    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useQueryVerifyLoginEmail = (payload, onSuccess, onError) => {
  const queryKey = [queryKeys.verifyCredentials, payload];
  const queryFn = ({ queryKey }) => axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/login`, queryKey[1]);

  return useQuery(queryKey, queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess,
    onError,
  });
};

export const useMutationForgotPassword = (payload, toast, setIsLogin) => {
  const queryKey = [queryKeys.forgotPassword, payload];
  const queryFn = () => axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/forgot-password`, payload);

  return useMutation(queryKey, queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });
      setIsLogin(true);
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized && payload?.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};

export const useQueryResetPassword = (payload, toast, navigate) => {
  const queryKey = [queryKeys.resetPassword, payload];
  const queryFn = () => axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/reset-password`, payload);

  return useQuery(queryKey, queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 2000,
      });
      navigate('/login');
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized && payload?.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};

// coaches add edit delete flow
export const useQueryGetCoachesList = (payload, navigate, toast) => {
  const queryKey = [queryKeys.getCoachesList, payload];

  const queryFn = ({ queryKey }) =>
    axios.get(
      queryKey[1].role === 'teacher'
        ? `${process.env.REACT_APP_SERVER_URL}/api/teacher/get-edifiers`
        : queryKey[1].role === 'admin'
        ? `${process.env.REACT_APP_SERVER_URL}/api/admin/coaches`
        : queryKey[1].role === 'site-leader' && `${process.env.REACT_APP_SERVER_URL}/api/site-leader/users`,
      {
        headers: { Authorization: `Bearer ${queryKey[1].token}` },
      }
    );
  return useQuery(queryKey, !payload.token ? logOutUser(navigate) : queryFn, {
    enabled: true,
    retry: 0,
    cacheTime: 3572000,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useQueryGetTeachersList = (userToken, navigate, toast) => {
  const queryKey = [queryKeys.getTeachersList];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/teacher/get-pairs`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: 72000,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useQueryDeleteCoach = (payload, navigate, setDeleteOperationOpen, toast, queryClient, userRole) => {
  const queryKey = [queryKeys.deleteCoach, payload];

  const queryFn = ({ queryKey }) =>
    axios.delete(
      `${process.env.REACT_APP_SERVER_URL}${
        userRole !== 'site-leader'
          ? `/api/admin/coach/delete/${queryKey[1]._id}`
          : `/api/site-leader/user/${queryKey[1]._id}`
      }`,
      {
        headers: { Authorization: `Bearer ${queryKey[1].token}` },
      }
    );

  return useQuery(queryKey, !payload.token ? logOutUser(navigate) : queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,
    onSuccess: () => {
      setDeleteOperationOpen(false);
      if (userRole === 'site-leader') {
        toast.success('Request sent to admin');
        return;
      }
      queryClient.invalidateQueries({ queryKey: [queryKeys.getCoachesList] });
    },

    onError: (e) => {
      setDeleteOperationOpen(false);

      if (e?.message?.includes('Network Error')) {
        toast.error('Backend Service Not Found');
        return;
      }

      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }

      if (e?.response?.status === httpStatuses.error) {
        toast.error('Either Server is Down or passed Item is already deleted');
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useMutationModifyCoachRecord = (
  payload,
  toast,
  handleClose,
  userToken,
  setEdifierToEditData,
  navigate,
  queryClient,
  userRole
) => {
  const queryKey = [queryKeys.modifyCoachRecord, payload];
  const queryFn = () =>
    axios.put(
      userRole === 'site-leader'
        ? `${process.env.REACT_APP_SERVER_URL}/api/site-leader/user`
        : `${process.env.REACT_APP_SERVER_URL}/api/admin/coach/update`,
      payload,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });
      if (userRole !== 'site-leader') {
        queryClient.invalidateQueries({ queryKey: [queryKeys.getCoachesList] });
      }
      setEdifierToEditData({});

      handleClose();
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses?.unauthorized && payload?.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};

// coaches add edit delete flow

// Manage Credentials Change Role Here

export const useMutationManageCredentials = (
  payload,
  toast,
  handleClose,
  userToken,
  setUserToEdit,
  navigate,
  queryClient
) => {
  const queryKey = [queryKeys.manageCredentials, payload];
  const queryFn = () =>
    axios.put(`${process.env.REACT_APP_SERVER_URL}/api/admin/update-credentials`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success.data.message}`, {
        duration: 5000,
      });
      setUserToEdit({});
      queryClient.invalidateQueries({ queryKey: [queryKeys.getAllUsers] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.getCoachesList] });

      handleClose();
    },
    onError: (error) => {
      try {
        if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
          logOutUser(navigate);
          return;
        }
        generateToastError(toast, error);
      } catch (e) {
        toast.error('The errror ', e);
      }
    },
  });
};

export const useQueryGetUsersList = (payload, navigate, toast) => {
  const queryKey = [queryKeys.getAllUsers, payload];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/users`, {
      headers: { Authorization: `Bearer ${queryKey[1].token}` },
    });

  return useQuery(queryKey, !payload.token ? logOutUser(navigate) : queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

// Manage Credentials Change Role Here

export const useQueryGetNotifications = (toast, userToken, navigate, queryClient) => {
  const queryKey = [queryKeys.getAllNotifications];
  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/notification/get-notifications`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
console.log("QUERRY FN",queryFn)
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,
    // refetchInterval: 10000,
    // onSuccess: (e) => {
    //   if (
    //     e?.data?.data &&
    //     JSON.stringify(e?.data?.data)?.split(false).length - 1 > 0 &&
    //     window.location.pathname.includes('meeting-invites')
    //   ) {
    //     queryClient.invalidateQueries({ queryKey: [queryKeys.getUpcomingMeetingsList] });
    //   }
    // },

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }

      generateToastError(toast, e);
    },
  });
};

export const useMutationReadAllNotifications = (
  toast,
  userToken,

  queryClient
) => {
  const queryKey = [queryKeys.readAllNotifications];
  const queryFn = () =>
    axios.patch(
      `${process.env.REACT_APP_SERVER_URL}/api/notification/update-notifications`,
      {
        isRead: true,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      // toast.success(`${success?.data?.message}`, {
      //   duration: 10000,
      // });

      queryClient.invalidateQueries({ queryKey: [queryKeys.getAllNotifications] });
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      // generateToastError(toast, error);
    },
  });
};

// all timeslots flow

export const userQueryGetTimeSlots = (toast, userToken, userId) => {
  const queryKey = [queryKeys.timeSlots];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/availability/get-availability/${userId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    cacheTime: 0,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      generateToastError(toast, e);
    },
  });
};

export const useMutationModifyTimeSlots = (payload, toast, userToken, setRefactoredSlots) => {
  const queryKey = [queryKeys.modifyTimeSlots, payload];
  const queryFn = () =>
    axios.put(`${process.env.REACT_APP_SERVER_URL}/api/availability/update-availability`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });

      setRefactoredSlots([]);
    },
    onError: (error) => {
      setRefactoredSlots([]);

      if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};

export const useMutationSendInviteNotifications = (payload, toast, userToken, queryClient) => {
  const queryKey = [queryKeys.inviteNotifications, payload];
  const queryFn = () =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/invite/send-invite`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 5000,
      });

      queryClient.invalidateQueries({ queryKey: [queryKeys.getSentInvites] });
    },
    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

// all timeslots flow

//meeting Invites flow

export const useQueryGetUserInvitesList = (userToken, navigate, toast) => {
  const queryKey = [queryKeys.getUserInvites];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/invite/get-invites`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    cacheTime: 0,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useMutationModifyMeetingInvite = (
  payload,
  toast,
  userToken,
  setMeetingInvitePayload,

  navigate,
  queryClient
) => {
  const queryKey = [queryKeys.modifyMeetingInviteStatus, payload];
  const queryFn = () =>
    axios.patch(`${process.env.REACT_APP_SERVER_URL}/api/invite/update-invite`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`);
      setMeetingInvitePayload({});

      queryClient.invalidateQueries({ queryKey: [queryKeys.getUserInvites] });

      queryClient.invalidateQueries({ queryKey: [queryKeys.getUpcomingMeetingsList] });
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};
//meeting Invites flow

//Upcoming Meetings flow

//Upcoming Meetings flow

// sent invites api flow

export const useQueryGetSentInvites = (userToken, navigate, toast) => {
  const queryKey = [queryKeys.getSentInvites];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/invite/sent-invites`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    cacheTime: 0,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

// sent invites api flow

// surveys api flow

export const useMutateSubmitFirstSession = (
  payload,
  navigate,
  toast,
  userToken,
  checkoutUser,
  sessionId,
  queryClient
) => {
  const queryKey = [queryKeys.firstSessionLog, payload];

  const queryFn = () =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/survey/save/${sessionId}`, payload, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,
    onSuccess: (success) => {
      toast.success(`Your response is recorded`, {
        duration: 3000,
      });
      const leaveBtnElement = document.getElementsByClassName('leave-meeting-options__btn--default')[0];
      if (leaveBtnElement) {
        leaveBtnElement.style.pointerEvents = 'none';
        leaveBtnElement.style.opacity = '0.5';
      }
      // navigate('/dashboard/app');
      document.getElementById('zoom-modal').style.display = 'none';
      window.ZoomMtg.leaveMeeting({
        cancelCallback: function () {
          // Your code here for cancel callback
        },
        confirm: true, // Set to true if you want to show a confirmation before leaving
        error: function (error) {
          // Your code here for error callback
        },
        success: function () {
          // Your code here for success callback
          console.log('Successfully left the meeting');
        },
      });

      checkoutUser();

      queryClient.invalidateQueries({ queryKey: [queryKeys.surveyStatus] });
    },

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

// surveys api flow

//survey apis

export const useQueryGetSurveyStatus = (userToken, navigate, toast, sessionId) => {
  const queryKey = [queryKeys.surveyStatus];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/survey/${sessionId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, sessionId ? queryFn : () => {}, {
    enabled: true,
    retry: 0,
    cacheTime: 0,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useQueryGetTotalUsers = (token, navigate, userRole) => {
  const queryKey = [queryKeys.totalUsers];

  const queryFn = ({ queryKey }) =>
    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/${
        userRole === 'TEACHER' ? 'teacher' : userRole === 'ADMIN' ? 'admin' : 'edifier'
      }/analytics`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

  return useQuery(
    queryKey,
    userRole === 'TEACHER' || userRole === 'COACH' || userRole === 'ADMIN' ? queryFn : () => {},
    {
      enabled: true,
      retry: 0,
      cacheTime: 72000,

      refetchOnWindowFocus: false,

      staleTime: Infinity,

      onError: (e) => {
        if (e?.response?.status === httpStatuses.unauthorized) {
          logOutUser(navigate);
          return;
        }
        // generateToastError(toast, e);
      },
    }
  );
};

//survey apis

// set User availability admin side

export const useMutationSetUserAvailability = (
  toast,
  userToken,
  payload,

  queryClient,
  setAvailabilityPayload
) => {
  const queryKey = [queryKeys.setUserAvailability, payload];
  const queryFn = () => {
    const userId = payload['_id'];
    delete payload['_id'];

    return axios.patch(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/update-availability/${userId}`,
      {
        ...payload,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
  };

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 10000,
      });
      setAvailabilityPayload({});

      queryClient.invalidateQueries({ queryKey: [queryKeys.getAllUsers] });
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized && payload?.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      // generateToastError(toast, error);
    },
  });
};

// set User availability admin side

//Send Bulk Messages

export const useMutationSendBulkNotifications = (
  payload,
  toast,
  userToken,
  setBulkMessagesPayload,
  setSelectedUsers,
  setValue,
  setSelectedRoles
) => {
  const queryKey = [queryKeys.sendBulkMessages, payload];
  const queryFn = () =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/send-notifications`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });
      setBulkMessagesPayload({});
      setSelectedUsers([]);
      setValue('message', '');
      setSelectedRoles({ edifiers: false, teachers: false, siteLeaders: false, all: false });
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};

//Send Bulk Messages
