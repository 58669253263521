import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogContent from '@mui/material/DialogContent';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Select } from '../select';
import { teachersOnboardingTextFields } from '../../_mock';
import { FileUpload } from '../file-upload';

export default function AlertDialog({ open, handleClose, addSchool, setSchoolData, schoolData, isLoading }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({});

  const [previewImage, setPreviewImage] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if (Object.keys(schoolData).length !== 0) {
      addSchool();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolData]);

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.size > 10 * 1024 * 1024) {
      toast.error('Resolve the Errors');
      return;
    }

    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    formData.append('image', selectedImage);

    setSchoolData({ formData });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: { xs: '100% !important', sm: '649px' },
            minWidth: { xs: '90%', sm: 'auto' },
            height: 'max-content',
            borderRadius: '20px',
          },
        }}
      >
        <Box sx={{ textAlign: 'right', p: '20px 30px 0 0', cursor: 'pointer' }} onClick={(e) => handleClose(e)}>
          <CloseIcon />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography variant="h3" sx={{ fontWeight: 400, color: '#000', fontFamily: "'Noto Sans JP'", mb: '14px' }}>
              Add School
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
              Thank you for your dedication to the teaching profession and your interest in supporting new teachers in our growing community.
            </Typography>

            <FileUpload
              setPreviewFile={setPreviewImage}
              setSelectedFile={setSelectedImage}
              previewFile={previewImage}
              selectedFile={selectedImage}
            />
            <TextField
              name="name"
              label="School Name"
              placeholder="School Name Here"
              fullWidth
              {...register(
                'name',

                {
                  required: `School name is required`,
                  maxLength: { value: 30, message: 'School name cannot exceed 70 characters' },
                }
              )}
              error={errors && !!errors?.name}
              helperText={errors?.name?.message}
              type="text"
              sx={{ mb: '17px', '& .MuiFormHelperText-root': { margin: 0 } }}
            />
            <TextField
              name="address"
              label="Address"
              placeholder="Address Here"
              fullWidth
              // {...register('confirmPassword')}
              type="text"
              sx={{ mb: '10px', '& .MuiFormHelperText-root': { margin: 0 } }}
              {...register(
                'state',

                {
                  required: `Address is required`,
                  maxLength: { value: 70, message: 'Address cannot exceed 70 characters' },
                }
              )}
              error={errors && !!errors?.state}
              helperText={errors?.state?.message}
            />

            <Select
              register={register}
              field={{ ...teachersOnboardingTextFields[10], label: 'District', name: 'district' }}
              errors={errors}
              name={'district'}
              watch={watch}
              defaultValue={watch('district') || ''}
              onChange={(e) => setValue('district', e.target.value)}
              items={teachersOnboardingTextFields[10].items}
              schoolDistrict
            />

            {/*
            <TextField
              name="district"
              label="District"
              placeholder="District Here"
              fullWidth
              // {...register('confirmPassword')}
              type="text"
              sx={{ mb: '17px', '& .MuiFormHelperText-root': { margin: 0 } }}
              {...register(
                'district',

                {
                  required: `District is required`,
                  maxLength: { value: 30, message: 'District Name cannot exceed 30 characters' },
                }
              )}
              error={errors && !!errors?.district}
              helperText={errors?.district?.message}
            /> */}
          </DialogContent>

          <Box sx={{ textAlign: 'right', pr: '31px', pb: '37px' }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="form"
              sx={{ width: '164px', height: '48px', mr: { xs: '0', sm: '4px' }, mb: { xs: '10px', sm: '0' } }}
              type="submit"
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              color="form"
              sx={{ width: '164px', height: '48px' }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Dialog>
    </div>
  );
}
