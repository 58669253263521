import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <div style={{ paddingTop: '2rem', paddingLeft: '16rem', paddingRight: '16rem', textAlign: 'justify' }}>
      <button
        onClick={() => navigate("/login")}
        style={{
          display: 'block',
          fontSize: '14px',
          fontWeight: 400,
          color: '#126FD6',
          textDecoration: 'none',
          fontFamily: "'Inter', sans-serif",
          marginTop: '10px',
          backgroundColor: 'white',
          border: 'none',
          cursor: 'pointer',
          padding: '0',
          outline: 'none',
        }}
        onMouseOver={(e) => {
          e.target.style.fontWeight = 'bold';
        }}
        onMouseOut={(e) => {
          e.target.style.fontWeight = 'normal';
        }}
        onFocus={(e) => {
          e.target.style.fontWeight = 'bold';
        }}
        onBlur={(e) => {
          e.target.style.fontWeight = 'normal';
        }}
      >
        Back to Login
      </button>
      <h1>
        <strong>Terms Of Use</strong>
      </h1>{' '}
      <br />
      <h4>
        <strong>Last updated July 21, 2022.</strong>
      </h4>{' '}
      <p style={{ marginTop: '1rem' }}>
        The Edifying Teachers services, tools, and network (collectively, “Edifying Teachers” or “the Service”) are
        operated by Edifying Teachers, LLC and its affiliates (collectively, “us”, “we” or “the Company”). By accessing
        or using our web site at <a href="https://www.EdifyingTeachers.com" target="_blank" rel="noopener noreferrer">www.EdifyingTeachers.com </a>
        and any of our other websites and their respective subdomains
        (the “Site”) or by posting on the Site, you (the “User”) signify that you have read, understand and agree to be
        bound by these Terms of Use (“Terms of Use” or “Agreement”), whether or not you are a registered member of the
        Service. We reserve the right, at our sole discretion, to change, modify, add, or delete portions of these Terms
        of Use at any time. If we do this, we will post the changes to these Terms of Use on this page and will indicate
        at the top of this page the date these terms were last revised. Your continued use of the Service or the Site
        after any such changes constitutes your acceptance of the new Terms of Use. If you do not agree to abide by
        these or any future Terms of Use, do not use or access (or continue to use or access) the Service or the Site.
        It is your responsibility to regularly check the Site to determine if there have been changes to these Terms of
        Use and to review such changes.
      </p>
      <strong>
        PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
        REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT
        GOVERNS HOW DISPUTES WILL BE RESOLVED.{' '}
      </strong>
      <h3>
        <strong> Eligibility </strong>
      </h3>
      This Site is intended solely for users who are thirteen (13) years of age or older. Any registration by, use of or
      access to the Site by anyone who is under 13 is unauthorized, unlicensed and in violation of these Terms of Use.
      By using the Service or the Site, you represent and warrant that you are 13 or older, and that you agree to and
      will abide by all of the terms and conditions of this Agreement.
      <br />
      <h3>
        <strong> Registration Data; Account Security </strong>
      </h3>
      In order to access the Site and the Service, you may be required to become a Registered User. For purposes of this
      Agreement, a “Registered User” is a user who has registered an account on the Site (“Account”). In consideration
      of your use of the Site, you agree to: <br />
      1. provide accurate, current and complete information about you as may be prompted by any registration forms on
      the Site (“Registration Data”);
      <br />
      2. maintain the security of your password and identification;
      <br />
      3. maintain and promptly update the Registration Data, and any other information you provide to Company, to keep
      it accurate, current and complete; and
      <br />
      4. be fully responsible for all use of your Account and for any actions that take place using your Account
      including any content uploaded by you.
      <br />
      <h3>
        <strong> Student Records </strong>
      </h3>{' '}
      When we provide Services to students on behalf of educational institutions, we may be directed to collect
      personally identifiable information from or about such students to enroll them as Registered Users of the Site
      (“Student Data”). Our collection, use, and sharing of Student Data is governed by our contracts with such
      educational institutions, the provisions of FERPA, the Children’s Online Privacy Protection Act (“COPPA”), and
      other applicable laws that relate to the collection and use of personal information of students. Each educational
      institution that purchases a subscription for students to use the Services on the Site will have access to a
      user-friendly administrator dashboard that allows direct control over the Student Records at all times. The
      administrator(s) appointed by the educational institution can create, update, review, modify, and delete
      individual accounts, and monitor logins within the individual accounts. The Company will solely use the Student
      Records for the purpose of providing and enhancing teacher and student use of the Services. For more information
      about Student Records, see our Privacy Policy. <br />
      <h3>
        <strong> Proprietary Rights </strong>
      </h3>{' '}
      in Site Content; Limited License All content on the Site and available through the Service (other than any content
      you upload), including but not limited to designs, text, graphics, pictures, video, information, applications,
      software, sound and other files, and their selection and arrangement (the “Site Content”), are the proprietary
      property of the Company, its users or its licensors with all rights reserved. No Site Content may be modified,
      copied, distributed, framed, reproduced, republished, downloaded, displayed, posted, transmitted, or sold in any
      form or by any means, in whole or in part, without the Company’s prior written permission, except that the
      foregoing does not apply to your own User Content (as defined below) that you legally post on the Site. Provided
      that you are eligible to use the Site, you are granted a limited license to access and use the Site and the Site
      Content during the term of the subscription(s) purchased by you or by an organization on your behalf. If permitted
      by the copyright holder of such content, this limited license includes authorization to download or print a copy
      of any portion of the Site Content to which you have properly gained access solely for your personal and internal
      business use, provided that you keep all copyright or other proprietary notices intact. Except for your own User
      Content, you may not upload or republish Site Content on any Internet, Intranet or Extranet site or incorporate
      the information in any other database or compilation, and any other use of the Site Content is strictly
      prohibited. Such license is subject to these Terms of Use and does not include use of any data mining, robots or
      similar data gathering or extraction methods. Any use of the Site or the Site Content other than as specifically
      authorized herein, without the prior written permission of Company, is strictly prohibited and will terminate the
      license granted herein. Such unauthorized use may also include use which violates applicable laws, including
      without limitation copyright and trademark laws and applicable communications regulations and statutes. Unless
      explicitly stated herein, nothing in these Terms of Use shall be construed as conferring any license to
      intellectual property rights, whether by estoppel, implication or otherwise. This license will expire at the end
      of your subscription term and is revocable at any time without notice and with or without cause.
      <h3>
        <strong> Trademarks </strong>
      </h3>
      Edifying Teachers, Edifying Principals, Edifying Educators, and other Company graphics, logos, designs, page
      headers, button icons, scripts and service names are registered trademarks, trademarks or trade dress of Company
      in the U.S. and/or other countries. Company’s trademarks and trade dress may not be used, including as part of
      trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely
      to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written
      permission of the Company.
      <h3>
        <strong> User Conduct </strong>
      </h3>{' '}
      You understand that the Service and the Site are available for your personal and internal business use only. You
      represent, warrant and agree that no materials of any kind submitted through your account or otherwise posted,
      transmitted, or shared by you on or through the Service will violate or infringe upon the rights of any third
      party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain
      libelous, defamatory or otherwise unlawful material. <br />
      In addition, you agree not to use the Service or the Site to: <br />
      <ul>
        <li>
          Harvest or collect email addresses or other contact information of other users from the Service or the Site by
          electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications.
        </li>
        <li>
          Use the Service or the Site in any unlawful manner or in any other manner that could damage, disable,
          overburden, or impair the Site.
        </li>
        <li>Use automated scripts to collect information from or otherwise interact with the Service or the Site.</li>
        <li>
          Upload, post, transmit, share, store, or otherwise make available any content that we deem to be harmful,
          threatening, unlawful, defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent,
          invasive of privacy or publicity rights, hateful, or racially, ethnically, or otherwise objectionable.
        </li>
        <li>
          Register for a User account on behalf of an individual other than yourself, or register for a User account on
          behalf of any group or entity.
        </li>
        <li>
          Impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age, or your
          affiliation with any person or entity.
        </li>
        <li>
          Upload, post, transmit, share, or otherwise make available any unsolicited or unauthorized advertising,
          solicitations, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other
          form of solicitation.
        </li>
        <li>
          Upload, post, transmit, share, store, or otherwise make publicly available on the Site any private information
          of any third party, including, without limitation, addresses, phone numbers, email addresses, Social Security
          numbers, and credit card numbers.
        </li>
        <li>Solicit personally identifying information for unlawful purposes.</li>
        <li>
          Upload, post, transmit, share, or otherwise make available any material that contains software viruses or any
          other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any
          computer software or hardware or telecommunications equipment.
        </li>
        <li>Intimidate or harass another.</li>
        <li>
          Upload, post, transmit, share, store, or otherwise make available content that would constitute, encourage, or
          provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create
          liability or violate any local, state, national, or international law.
        </li>
        <li>
          Use or attempt to use another’s account, service, or system without authorization from the Company, or create
          a false identity on the Service or the Site.
        </li>
        <li>
          Upload, post, transmit, share, store, or otherwise make available content that, in the sole judgment of the
          Company, is objectionable or which restricts or inhibits any other person from using or enjoying the Site, or
          which may expose the Company or its users to any harm or liability of any type.
        </li>
      </ul>
      <h3>
        <strong> User Content Posted on the Site </strong>
      </h3>{' '}
      Edifying Teachers is concerned about your privacy and has developed a policy to address privacy concerns. Please
      see our Privacy Policy regarding the collection and use of your information. By using this Site, you are
      consenting to the terms of our Privacy Policy.
      <br />
      You are solely responsible for the profiles, messages, videos, images, notes, text, information, and other content
      that you upload, publish or display (hereinafter, “post”) on or through the Service or the Site, or transmit to or
      share with other users (collectively the “User Content”). For example, if you post any video footage containing
      images of students, you must have obtained their parents’ written permission. If you are using Edifying Teachers
      on behalf of an educational agency or institution and FERPA applies, before you enter, upload or access any
      educational records of a minor student, or share such information with other users, you must confirm that your
      agency or institution has (1) obtained appropriate consent from the parent or guardian of that student or (2)
      determined that one of the limited exceptions to the consent requirement applies. You can find more information on
      FERPA and related guidance here. <br />
      You may not post, transmit, or share User Content on the Site or Service that you did not create or that you do
      not have permission to post. You understand and agree that the Company may, but is not obligated to, review the
      Site and may delete or remove (without notice) any Site Content or User Content in its sole discretion, for any
      reason or no reason, including without limitation User Content that in the sole judgment of the Company violates
      this Agreement, or which might be offensive, illegal, or that might violate the rights, harm, or threaten the
      safety of users or others. You understand and agree that you may be exposed to User Content that is inaccurate,
      objectionable, inappropriate for children, or otherwise unsuited to your purpose, and you agree that the Company
      will not be liable for any damages you allege to incur as a result of User Content. You are solely responsible at
      your sole cost and expense for creating backup copies and replacing any User Content you post or store on the Site
      or provide to the Company. <br />
      When you post User Content to the Site, you authorize and direct us to make such copies thereof as we deem
      necessary in order to facilitate the posting and storage of the User Content on the Site. This may include sharing
      your User Content with your school or school district upon their request. You may remove your User Content from
      the Site at any time. Upon expiration or termination of the limited license granted to you to allow you to access
      and use the Site and the Site Content, we may, but are not required to, retain copies of your User Content in an
      archival copy if such archival copy is stored on our server and cannot be removed or deleted. Additionally, copies
      of any User Content you remove from the Site may remain viewable in cached and archived pages of the Service.
      <br />
      <h3>
        <strong> DMCA Copyright Policy </strong>
      </h3>{' '}
      We operate the Service in compliance with 17 U.S.C. §512 and the Digital Millennium Copyright Act (“DMCA”). It is
      our policy to respond to any infringement notices and take appropriate actions under the DMCA and other applicable
      intellectual property laws. It is our policy to promptly process and investigate notices of alleged copyright
      infringement, and take appropriate actions under the DMCA. The DMCA requires that all notices of alleged copyright
      infringement must be in writing. When informing the designated agent of an alleged copyright infringement, the
      complainant must do the following: <br />
      1. Identify the copyrighted work that allegedly has been infringed. If multiple copyrighted works at a single
      online site are involved, please provide a list of the works on that site;
      <br />
      2. Describe the material that is claimed to be infringing and provide sufficient information to permit us to
      locate that material;
      <br />
      3. Provide your contact information, including an address, telephone number, and, if available, an email address;
      <br />
      4. Certify or include a statement that the complainant has a good faith belief that the use of the
      copyright-protected material in the manner complained of is not authorized by the copyright owner, the owner’s
      agent, or law; <br />
      5. Certify that the information that you have provided to us is accurate. The complainant should attest under
      penalty of perjury that the complainant is authorized to enforce the copyrights that have allegedly have been
      infringed; and
      <br />
      6. Include a physical or electronic signature of the copyright owner or person authorized to act on behalf of the
      owner.
      <br />
      Before the complainant alleges an infringement, complainant should consult copyright materials to confirm that the
      use is, in fact, infringing. The United States Copyright Office provides basic information, online, at
      <a href="http://www.copyright.gov/circs/circ01.pdf" target="_blank" rel="noopener noreferrer"> http://www.copyright.gov/circs/circ01.pdf</a>,
      which can assist one in determining whether an exception or defense,
      such as fair use, may apply to the use of your copyrighted work.
      <br />
      We expect all of the Users of our Service to comply with applicable copyright laws. However, if we are notified of
      a claimed copyright infringement, or otherwise become aware of facts and circumstances from which infringement is
      apparent, we will respond appropriately, which may include removing or disabling access to the material that is
      claimed to be infringing. We will follow the procedures outlined in the DMCA with regard to appropriate
      notifications of the User and the complaining party, acceptance of counter notifications, and, where indicated,
      “putback” of the alleged infringing material. Refer to the United States Copyright Office for the provisions of
      the DMCA at <a href="http://www.loc.gov/copyright/legislation/dmca.pdf" target="_blank" rel="noopener noreferrer"> http://www.loc.gov/copyright/legislation/dmca.pdf</a>. Where it has been clearly established that a User
      is a repeat offender, we may, in our sole discretion, terminate that User’s account. Pursuant to the DMCA, we have
      designated an agent to receive notification of alleged copyright infringement occurring on web pages or computer
      servers. If you believe that your copyrighted work is being infringed on the Service, please notify our designated
      agent at: NQ Resident Agent Services LLC, One South Street 27th Floor, Baltimore, MD 21202.
      <br />
      <h3>
        <strong> Third Party Websites and Content </strong>
      </h3>
      Parties other than Edifying Teachers provide services or sell products on this Site. In addition, we provide links
      to the sites of affiliated companies and certain other businesses, (or you may be sent through the Site or the
      Service) links to other websites (“Third Party Sites”) as well as applications, articles, photographs, text,
      graphics, pictures, designs, sound, video, information, applications, software and other content or items
      belonging to or originating from third parties (the “Third Party Applications, Software or Content”). Such Third
      Party Sites and Third Party Applications, Software or Content are not investigated, monitored or checked for
      accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party Sites accessed
      through the Site or any Third Party Applications, Software or Content posted on, available through or installed
      from the Site, including without limitation the content, accuracy, offensiveness, opinions, reliability, privacy
      practices or other policies of or contained in the Third Party Sites or the Third Party Applications, Software or
      Content. Inclusion of, linking to or permitting the use or installation of any Third Party Site or any Third Party
      Applications, Software or Content does not imply approval or endorsement thereof by us. If you decide to leave the
      Site and access the Third Party Sites or to use or install any Third Party Applications, Software or Content, you
      do so at your own risk and you should be aware that the Third Party Site terms and policies will apply with
      respect to such Third Party Applications, Software, or Content. You should review the applicable terms and
      policies, including privacy and data gathering practices, of any site to which you navigate from the Site or
      relating to any applications you use or install from the site. <br />
      <h3>
        <strong> Pricing </strong>
      </h3>
      Access to the Site and the Services is offered on a subscription basis. Unless otherwise agreed to by the parties
      or as set forth on an order form, fees for the entire subscription term must be paid in full prior to the start of
      the term and all fees for any renewal periods will be based upon Edifying Teachers’s then-prevailing subscription
      rates. All pricing quoted is in United States Dollars and are exclusive of taxes. All sales are final and payments
      are non-refundable.
      <br />
      <h3>
        <strong> User Disputes </strong>
      </h3>
      You are solely responsible for your interactions with other Edifying Teachers users. We reserve the right, but
      have no obligation, to monitor disputes between you and other users.
      <h3>
        <strong> Privacy </strong>
      </h3>{' '}
      We care about the privacy of our users. Click here to view our Privacy Policy. By using the Site or the Service,
      you are consenting to have your personal data transferred to and processed in the United States.
      <h3>
        <strong> Disclaimers </strong>
      </h3>{' '}
      The Company is not responsible or liable in any manner for any User Content or Third Party Applications, Software
      or Content posted on the Site or in connection with the Service, whether posted or caused by users of the Site, by
      Edifying Teachers employees, by third parties or by any of the equipment or programming associated with or
      utilized in the Site or the Service. Although we provide rules for user conduct and postings, we do not control
      and are not responsible for what users post, transmit or share on the Site and are not responsible for any
      offensive, inappropriate, obscene, unlawful or otherwise objectionable content you may encounter on the Site or in
      connection with any User Content or Third Party Applications, Software or Content. The Company is not responsible
      for the conduct, whether online or offline, of any user of the Site or Service.
      <br />
      The Site and the Services may be temporarily unavailable from time to time for maintenance or other reasons.
      Company assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or
      transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, User
      communications. The Company is not responsible for any technical malfunction or other problems of any telephone
      network or service, computer systems, servers or providers, computer or mobile phone equipment, software, failure
      of email or players on account of technical problems or traffic congestion on the Internet or at any Site or
      combination thereof, including injury or damage to User’s or to any other person’s computer, mobile phone, or
      other hardware or software, related to or resulting from using or downloading materials in connection with the Web
      and/or in connection with the Service. Under no circumstances will the Company be responsible for any loss or
      damage, including any loss or damage to any User Content or personal injury or death, resulting from anyone’s use
      of the Site or the Service, any User Content or Third Party Applications, Software or Content posted on or through
      the Site or the Service or transmitted to Users, or any interactions between users of the Site, whether online or
      offline.
      <br />
      THE SITE, THE SERVICES AND THE SITE CONTENT ARE PROVIDED “AS-IS” AND THE COMPANY DISCLAIMS ANY AND ALL
      REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF
      TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE COMPANY CANNOT GUARANTEE AND
      DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE SITE. COMPANY DOES NOT REPRESENT OR WARRANT THAT SOFTWARE,
      CONTENT OR MATERIALS ON THE SITE, THE SERVICE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE OR THAT THE
      SITE OR SERVICE ITS SERVERS, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE
      CAUTION IN THE USE AND DOWNLOADING OF CONTENT OR MATERIALS. WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND
      AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN CONTENT, MATERIAL, DATA OR SOFTWARE (INCLUDING ANY MOBILE CLIENT) FROM
      OR THROUGH THE SERVICE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE
      THEREOF AND ANY DAMAGES TO YOUR COMPUTER SYSTEM, LOSS OF DATA OR OTHER HARM OF ANY KIND THAT MAY RESULT.
      <br />
      The Company reserves the right to change any and all content, software and other items used or contained in the
      Site and any Services offered through the Site at any time without notice. Reference to any products, services,
      processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute
      or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by Company.
      <br />
      <h3>
        <strong> Limitation on Liability </strong>
      </h3>{' '}
      IN NO EVENT WILL COMPANY OR ITS DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY
      INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR
      LOST DATA ARISING FROM YOUR USE OF THE SITE OR THE SERVICE, ANY APPLICATIONS OR ANY OF THE SITE CONTENT OR OTHER
      MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE SITE, EVEN IF THE COMPANY IS AWARE OR HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COMPANY’S
      LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
      TO THE AMOUNT PAID, IF ANY, BY YOU TO COMPANY FOR THE SERVICE DURING THE TERM OF MEMBERSHIP, BUT IN NO CASE WILL
      THE COMPANY’S LIABILITY TO YOU EXCEED $1,000. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO COMPANY FOR THE SERVICE,
      YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO
      DAMAGES OF ANY KIND FROM COMPANY, REGARDLESS OF THE CAUSE OF ACTION. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS
      ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL
      OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
      <h3>
        <strong> Termination </strong>
      </h3>{' '}
      The Company may terminate your account, delete your profile and any content or information that you have posted on
      the Site and/or prohibit you from using or accessing the Service or the Site or any Site Content (or any portion,
      aspect or feature of the Service or the Site or Site Content) for any reason, or no reason, at any time in its
      sole discretion, with or without notice, including without limitation if it believes that you are under 13, or
      that you have violated these terms.
      <h3>
        <strong> Governing Law; Venue and Jurisdiction </strong>
      </h3>{' '}
      By visiting or using the Site and/or the Service, you agree that the laws of the State of Maryland, without regard
      to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise
      between you and the Company or any of our affiliates. With respect to any disputes or claims not subject to
      arbitration (as set forth below), you agree not to commence or prosecute any action in connection therewith other
      than in the state and federal courts of Maryland, and you hereby consent to, and waive all defenses of lack of
      personal jurisdiction and forum non conveniens with respect to, venue and jurisdiction in the state and federal
      courts of Maryland.{' '}
      <h3>
        <strong> Arbitration </strong>
      </h3>{' '}
      You each agree to resolve any claim, dispute, or controversy (excluding claims for injunctive or other equitable
      relief) arising out of or in connection with or relating to these Terms of Use by binding arbitration by the
      American Arbitration Association (“AAA”) under the Commercial Arbitration Rules and Supplementary Procedures for
      Consumer Related Disputes then in effect for the AAA, except as provided herein. Unless you and the Company agree
      otherwise, the arbitration will be conducted in Howard County. Each party will be responsible for paying any AAA
      filing, administrative and arbitrator fees in accordance with AAA rules. The award rendered by the arbitrator
      shall include costs of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other
      witnesses, and any judgment on the award rendered by the arbitrator may be entered in any court of competent
      jurisdiction. Nothing in this Section shall prevent either party from seeking injunctive or other equitable relief
      from the courts for matters related to data security, intellectual property or unauthorized access to the Service.
      ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
      PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE
      MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND COMPANY ARE EACH WAIVING THE
      RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.{' '}
      <h3>
        <strong> Indemnity </strong>
      </h3>{' '}
      You agree to indemnify and hold the Company, its subsidiaries and affiliates, and each of their directors,
      officers, agents, contractors, partners and employees, harmless from and against any loss, liability, claim,
      demand, damages, costs and expenses, including reasonable attorney’s fees, arising out of or in connection with
      any User Content or Content you post or share on or through the Site, your use of the Service or the Site, your
      conduct in connection with the Service or the Site or with other users of the Service or the Site, or any
      violation of this Agreement or of any law or the rights of any third party.
      <h3>
        <strong> Submissions </strong>
      </h3>{' '}
      You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about
      the Site or the Service (“Submissions”), provided by you to Company are non-confidential and shall become the sole
      property of Company. Company shall own exclusive rights, including all intellectual property rights, and shall be
      entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise,
      without acknowledgment or compensation to you.{' '}
      <h3>
        <strong> Other </strong>
      </h3>
      These Terms of Use constitute the entire agreement between you and Company regarding the use of the Site and/or
      the Service, superseding any prior agreements between you and Company relating to your use of the Site or the
      Service. You also may be subject to additional terms and conditions that may apply when you use certain Company
      Service, affiliate’s services, third-party content or third-party software. The failure of Company to exercise or
      enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision in
      that or any other instance. If any provision of this Agreement is held invalid, the remainder of this Agreement
      shall continue in full force and effect. If any provision of these Terms of Use shall be deemed unlawful, void or
      for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not
      affect the validity and enforceability of any remaining provisions.
      <h3>
        <strong> Questions </strong>
      </h3>{' '}
      Please send any questions to <a href="mailto:support@edifyingteachers.com">
        support@edifyingteachers.com</a> for more information.
      <br />
      <br />
      <br />
    </div>
  );
};

export default TermsAndConditions;
