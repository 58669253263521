import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, TextField, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../utils';
import { TagsInput } from '../customInput';
import { stepperBoxStyles } from '../../jsxStyles';
import { survey1Items, survey1SecondItems } from '../../_mock';
import { useQueryGetTotalUsers } from '../../hooks/react-query/useData';

const Surveys = ({ setPayload, apiPayload, isLoadingRequest, submitSurvey, participantId, firstLog }) => {
  const navigate = useNavigate();
  const userDetails = getUserDetails();
  const participantInfo = JSON.parse(localStorage.getItem('MeetingParticipantInfo'));
  const { data, isFetching: isLoading } = useQueryGetTotalUsers(userDetails?.token, navigate, userDetails?.role);
  const [checkedGoals, setCheckedGoals] = useState([]);
  const [checkboxGroupSelection, setCheckboxGroupSelection] = useState({
    touchOn: {
      "M's": { Modeling: false, Mentoring: false, Mindset: false, Mastering: false },
      "C's": { CollaborationWithConnection: false, CulturallySustainingConversation: false, CareerNavigation: false },
    },
    sharedWeekSummary: false,
  });
  const [goals, setGoals] = useState([]);
  const [previousGoals, setPreviousGoals] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({});

  useEffect(() => {
    if (Object.keys(apiPayload).length > 0) {
      submitSurvey();
    }
  }, [apiPayload]);

  const onSubmit = (submittedData) => {
    console.log(submittedData);
    let myObj = checkboxGroupSelection.touchOn["M's"];
    const MS = Object.keys(myObj).filter((key) => myObj[key] && myObj[key]);

    myObj = checkboxGroupSelection.touchOn["C's"];

    const CS = Object.keys(myObj).filter((key) => myObj[key] && myObj[key]);

    let updatedPayloadObj = { survey: false, Ms: MS, Cs: CS };

    updatedPayloadObj = { ...updatedPayloadObj, ...submittedData };

    updatedPayloadObj.survey = checkboxGroupSelection.sharedWeekSummary;
    setPayload({
      participantId,
      surveyData: { ...updatedPayloadObj, title: firstLog ? '1st Session Log' : 'All Sessions Log', checkedGoals },
    });
  };

  useEffect(() => {
    if (data) {
      const filteredGoals = data?.data.data.goals?.filter(
        (goal) => goal?.goalsFor?._id === participantInfo?.userId && !goal.goal.isCompleted
      );

      setPreviousGoals(filteredGoals);
    }
  }, [data]);

  const handleGoalCompletion = (goalId, isChecked) => {
    setPreviousGoals((prevGoals) =>
      prevGoals.map((goal) =>
        goal._id === goalId ? { ...goal, goal: { ...goal.goal, isCompleted: isChecked } } : goal
      )
    );
    setCheckedGoals((prevCheckedGoals) => {
      if (isChecked) {
        if (!prevCheckedGoals.includes(goalId)) {
          return [...prevCheckedGoals, goalId];
        }
      } else {
        return prevCheckedGoals.filter((id) => id !== goalId);
      }
      return prevCheckedGoals;
    });
  };
console.log(previousGoals)
  return (
    <Box sx={{ ...stepperBoxStyles, minWidth: '100%', overflow: 'scroll' }}>
      <Box
        sx={{ textAlign: 'right', cursor: 'pointer' }}
        onClick={() => {
          document.getElementById('zoom-modal').style.display = 'none';
        }}
      >
        <CloseIcon />
      </Box>
      <Typography
        variant="h3"
        sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", mb: '50px', textAlign: 'center' }}
      >
        {firstLog ? '1st Session Log' : 'All Sessions Log'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={'20px'} sx={{ mb: '50px' }}>
          <Grid item xs={12} md={4}>
            <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
              Ask your mentee to describe their goals:
            </Typography>
            <TagsInput
              placeholder="Separate Goals using Enter"
              setGoals={setGoals}
              goals={goals}
              register={register}
              errors={errors && errors}
              setValue={setValue}
            />
          </Grid>
        </Grid>

        {!firstLog ? (
          <Typography variant="h3" sx={{ fontWeight: 400, mb: '30px', lineHeight: '40px' }}>
            Previous Goals <span style={{ fontSize: '1rem' }}>(Check to Mark as Completed)</span>
          </Typography>
        ) : null}

        {!firstLog && previousGoals?.length > 0 && (
          <Box sx={{ mb: '50px', maxHeight: '400px', overflow: 'auto' }}>
            {previousGoals?.map((goal) => (
              <Box key={goal._id} sx={{ mb: '20px', display: 'flex', alignItems: 'center' }}>
                {/* Checkbox positioned on the left with styles for visibility */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={goal.goal.isCompleted}
                      // disabled={goal.goal.isCompleted}
                      onChange={(e) => handleGoalCompletion(goal._id, e.target.checked)} // Pass the checked state to the handleru
                      sx={{
                        color: 'primary.main', // Change the checkbox color to match your theme
                        '&.Mui-checked': {
                          color: 'primary.main', // Change checked color
                        },
                        '& .MuiSvgIcon-root': {
                          border: '1px solid #ccc', // Add border for visibility
                          backgroundColor: 'white', // Ensure the background is white
                        },
                      }}
                    />
                  }
                  sx={{ mr: 2 }}
                />
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {goal.goal.title}
                </Typography>
              </Box>
            ))}
          </Box>
        )}

        <Typography variant="h3" sx={{ fontWeight: 400, my: '30px', lineHeight: '30px' }}>
        During the session, which M's and C's did you address? (Check all that apply):
        </Typography>

        {['', "M's"].map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: 'inherit' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                width: '104px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
              }}
              variant="form"
            >
              {item}
            </Typography>

            {index === 0 &&
              survey1Items.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Modeling
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.Modeling || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-Modeling`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Modeling: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Mentoring
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.Mentoring || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-Mentoring`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Mentoring: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Mindset
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.touchOn[item]?.Mindset || false}
                      name={`${item}-Mindset`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Mindset: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Mastering
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.touchOn[item]?.Mastering || false}
                      name={`${item}-Mastering`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Mastering: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}

        {['', "C's"].map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: 'inherit' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
              lineHeight: '120px',
            }}
          >
            <Typography
              sx={{
                width: '104px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
              }}
              variant="form"
            >
              {item}
            </Typography>

            {index === 0 &&
              survey1SecondItems.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                    mt: '50px',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Collaboration with connection to resource/person(s)
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.CollaborationWithConnection || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-CollaborationWithConnection`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              CollaborationWithConnection: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Culturally sustaining conversation
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.CulturallySustainingConversation || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-CulturallySustainingConversation`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              CulturallySustainingConversation: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Career Navigation
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.touchOn[item]?.CareerNavigation || false}
                      name={`${item}-CareerNavigation`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              CareerNavigation: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}
        <Typography
          variant="h3"
          sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", my: '30px', lineHeight: '30px' }}
        >
          Please note: No other information will be shared with your school leadership. We at Edifying Teachers hold
          confidentiality in high regard.{' '}
        </Typography>

        <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Are there themes that emerged from our session that you would like to share with your school leaders?
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            fullWidth
            name="themes"
            {...register('themes', { required: `This is required` })}
            error={errors && !!errors?.themes}
            helperText={errors?.themes?.message}
          />
        </Box>
        {/* <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Verify that you have shared this week's survey during the end of the session by checking the box.
          </Typography>
          <Checkbox
            checked={checkboxGroupSelection.sharedWeekSummary || false}
            sx={{
              color: '#ADADAD',
            }}
            onChange={(e) =>
              setCheckboxGroupSelection({ ...checkboxGroupSelection, sharedWeekSummary: e.target.checked })
            }
          />
        </Box> */}

        <Box sx={{ textAlign: 'right' }}>
          <LoadingButton
            loading={isLoadingRequest}
            type="submit"
            variant="contained"
            color="accept"
            sx={{ color: 'white', mr: '20px', mt: '30px' }}
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default Surveys;
