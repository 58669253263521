import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import { imageBoxStyles } from '../../jsxStyles';

export default function MyDropzone({ setPreviewFile, setSelectedFile, previewFile, selectedFile, isVideo, isLoading }) {
  const onDrop = useCallback((acceptedFiles) => {
    const imageFile = acceptedFiles[0];

    if (imageFile instanceof File) {
      setSelectedFile(imageFile);
      setPreviewFile(URL.createObjectURL(imageFile));
    }
  }, [setPreviewFile, setSelectedFile]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: !isVideo
      ? {
          'image/jpeg': ['.jpeg'],
          'image/png': ['.png'],
          'image/webp': ['.webp'],
          'image/jpg': ['.jpg'],
          'image/gif': ['.gif'],
          'image/svg': ['.svg'],
        }
      : { 'video/mp4': ['.mp4'], 'video/mov': ['.mov'], 'video/avi': ['.avi'], 'video/webm': ['.webm'] },
    onDrop,
    minSize: 0,
    multiple: false,
  });

  return (
    <div style={{ width: 'fit-content', margin: !isVideo && '0 auto 39px auto' }}>
      <input {...getInputProps()} />

      {!isVideo ? (
        <Box
          {...getRootProps()}
          sx={{
            ...imageBoxStyles,
            backgroundImage: previewFile && `url(${previewFile})`,
            backgroundSize: 'contain', // Ensures the image is not stretched
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            border: previewFile ? '1px solid #ccc' : 'none',
            cursor: 'pointer',
          }}
        >
          {!previewFile && (
            <Typography
              variant="h3"
              sx={{ fontWeight: 400, color: '#FFF', fontFamily: "'Noto Sans JP'", mb: '14px', letterSpacing: '0.15px' }}
            >
              Change Image
            </Typography>
          )}
        </Box>
      ) : (
        <LoadingButton loading={isLoading} color="main" variant="video" {...getRootProps()}>
          <Box component="img" src="/assets/dashboard/videoUploadArrow.svg" sx={{ mx: 'auto', mb: '10px' }} />
          Add Video
        </LoadingButton>
      )}

      {!isVideo && selectedFile && selectedFile.size > 10 * 1024 * 1024 && (
        <Typography
          variant="error"
          sx={{ fontWeight: 300, fontSize: '11px', lineHeight: '20px', textAlign: 'center', mt: '20px' }}
        >
          Make sure the Image is not larger than 10MB
        </Typography>
      )}
    </div>
  );
}
