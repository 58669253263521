import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Typography, Box, TextField } from '@mui/material';

import toast from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/system';
import Logo from '../components/logo';
import { useQueryResetPassword } from '../hooks/react-query/useData';
import { validatePassword } from '../utils';


const BackgroundContainer = styled(Box)({
  // backgroundImage: 'url(/assets/loginFormBg.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'grid',
  placeItems: 'center',
  minHeight: '100vh',
});

export default function ResetPassword( ) {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [resetPasswordCredentials, setResetPasswordCredentials] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { isLoading: isResettingPassword, refetch: resetPassword } = useQueryResetPassword(
    resetPasswordCredentials,
    toast,
    navigate
  );

  useEffect(() => {
    if (Object.keys(resetPasswordCredentials).length > 0) {
      resetPassword();
    }
  }, [resetPasswordCredentials]);

  const onSubmit = (data) => {
    setResetPasswordCredentials({ newPassword: data?.newPassword, token: searchParams.get('token') });
  };

  return (
    <BackgroundContainer>
      <Box
        sx={{
          width: '400px',
          p: '24px',
          boxShadow: '0px 4px 24px rgba(149, 157, 165, 0.2)',
          borderRadius: '8px',
          background: 'white',
        }}
      >
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>
        <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.primary', mt: '12px' }}>
          Reset Password
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '24px' }}>
          <TextField
            name="newPassword"
            label="New Password"
            fullWidth
            {...register('newPassword', { required: 'Password is Required', validate: validatePassword })}
            error={!!errors.newPassword}
            helperText={errors?.newPassword && errors?.newPassword?.message}
            type="password"
            autoComplete="new-password"
            sx={{ mb: '16px' }}
          />

          <TextField
            name="confirmPassword"
            label="Confirm Password"
            fullWidth
            {...register('confirmPassword', {
              required: 'Confirm Password is required',
              validate: (value) => value === watch('newPassword') || 'Passwords do not match',
            })}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword && errors.confirmPassword.message}
            type="password"
            autoComplete="new-password"
            sx={{ mb: '16px' }}
          />

          <LoadingButton loading={isResettingPassword} type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            {isResettingPassword ? 'Submitting...' : 'Submit'}
          </LoadingButton>
        </form>
      </Box>
    </BackgroundContainer>
  );
}
